import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosIns from "apis/axios";

export const getMapData = createAsyncThunk(
  "map/getMapData",
  async ({ instance = axiosIns, query, url }, { rejectWithValue }) => {
    try {
      const res = await instance.get(url ?? `/poxes/map${query}`);
      return res.data;
    } catch (err) {
      if (!err.response) throw err;
      toast.error(err.response.data.message);
      return rejectWithValue(err.response.data);
    }
  }
);

const LAST_SELECTED_LAYER = "LAST_SELECTED_LAYER";
const LAST_SELECTED_PAY_TYPE = "LAST_SELECTED_PAY_TYPE";
const LAST_SELECTED_PARAM = "LAST_SELECTED_PARAM";
const LAST_SELECTED_FILTER_QUERIES = "LAST_SELECTED_FILTER_QUERIES";

const initialState = {
  isLoading: false,
  data: [],
  layer: localStorage.getItem(LAST_SELECTED_LAYER) ?? "Flat",
  payType: localStorage.getItem(LAST_SELECTED_PAY_TYPE) ?? "Rent",
  filterQueries: localStorage.getItem(LAST_SELECTED_FILTER_QUERIES) ?? "",
  param: localStorage.getItem(LAST_SELECTED_PARAM) ?? "rent",
};

const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    updateLayer(state, action) {
      state.layer = action.payload;
      localStorage.setItem(LAST_SELECTED_LAYER, action.payload);
    },
    updatePayType(state, action) {
      state.payType = action.payload;
      localStorage.setItem(LAST_SELECTED_PAY_TYPE, action.payload);
    },
    updateFilterQueries(state, action) {
      state.filterQueries = action.payload;
      localStorage.setItem(LAST_SELECTED_FILTER_QUERIES, action.payload);
    },
    updateParam(state, action) {
      state.param = action.payload;
      localStorage.setItem(LAST_SELECTED_PARAM, action.payload);
    },
    resetStorage(state) {
      localStorage.removeItem(LAST_SELECTED_LAYER);
      localStorage.removeItem(LAST_SELECTED_PAY_TYPE);
      localStorage.removeItem(LAST_SELECTED_FILTER_QUERIES);
      localStorage.removeItem(LAST_SELECTED_PARAM);
      state.layer = "Flat";
      state.payType = "Rent";
    },
    openMyPoxes(state) {
      state.layer = "";
      state.payType = "";
      state.filterQueries = "";
    },
    restoreFromMyPoxes(state) {
      state.layer = localStorage.getItem(LAST_SELECTED_LAYER) ?? "Flat";
      state.payType = localStorage.getItem(LAST_SELECTED_PAY_TYPE) ?? "Rent";
    },
    clearMapData(state) {
      state.data = [];
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMapData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMapData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.poxes ?? action.payload.saved_poxes ?? [];
    });
    builder.addCase(getMapData.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const {
  updateLayer,
  updatePayType,
  updateFilterQueries,
  updateParam,
  resetStorage,
  openMyPoxes,
  restoreFromMyPoxes,
  clearMapData,
} = mapSlice.actions;

export const getCurrentLayer = (state) => state.map.layer;

export default mapSlice.reducer;
