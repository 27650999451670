import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { toast } from "react-toastify";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const min_year = 2024;
const percentage_increase = 0.05;

const DataFormater = (number) => {
  if (number > 1000000000) {
    return (number / 1000000000).toLocaleString() + "B";
  } else if (number > 1000000) {
    return (number / 1000000).toLocaleString() + "M";
  } else if (number > 1000) {
    return (number / 1000).toLocaleString() + "K";
  } else {
    return number.toLocaleString();
  }
};

const CustomTooltip = ({ active = false, payload, label }) => {
  if (active && payload != null && payload?.length > 0) {
    return (
      <div className="chart-custom-tooltip">
        <span className="tooltip-label">{label}</span>
        {payload.map((p) => (
          <p key={p.name}>
            <span>{p.name}:</span>&nbsp;
            <span>{DataFormater(p.value)}</span>
          </p>
        ))}
      </div>
    );
  }

  return null;
};

export default function SalePredictionChart({ sale_price }) {
  const [selectedYears, setSelectedYears] = useState([2024, 2033]);
  const [range, setRange] = useState(10);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (range < 0) {
      toast.info("Range must be positive");
    } else if (selectedYears[0] < min_year) {
      toast.info(`Min year must start from ${min_year}`);
    } else if (selectedYears[1] - selectedYears[0] > 10) {
      toast.info("Range must be max of 10 years");
    } else {
      const data = Array.from(Array(range).keys()).map((e) => {
        return {
          year: selectedYears[0] + e,
          "Expected Price":
            sale_price *
            (1 + (selectedYears[0] - min_year + e) * percentage_increase),
        };
      });

      setData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range]);

  return (
    <div className="w-100">
      <div className="prediction-chart-heading">
        <span>Predictive Analysis</span>

        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const range = selectedYears[1] - selectedYears[0];
              setRange(range);
            }}
            className="filter-form"
          >
            <input
              type="number"
              name="from"
              id="from"
              value={selectedYears[0]}
              onChange={(e) => {
                setSelectedYears((prev) => [+e.target.value, prev[1]]);
              }}
            />
            <input
              type="number"
              name="to"
              id="to"
              value={selectedYears[1]}
              onChange={(e) => {
                setSelectedYears((prev) => [prev[0], +e.target.value]);
              }}
            />
            <button type="submit" title="Filter">
              <FaSearch />
            </button>
          </form>
          <span className="selected-years-range">{`${selectedYears[0]} - ${selectedYears[1]}`}</span>
        </div>
      </div>

      <div className="prediction-chart">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data} margin={{ left: -42, right: 8 }}>
            <XAxis dataKey="year" />
            <YAxis
              width={100}
              tickFormatter={DataFormater}
              label={{
                value: "Price (INR)",
                angle: -90,
                offset: 0,
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey="Expected Price"
              fill="#042954"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
