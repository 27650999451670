import "./styles.scss";
import { useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initGuidedTour } from "redux/features/other.slice";
import { FaPlus } from "react-icons/fa";
import { MapView, GuidedTourWrapper } from "components";
import logo_png from "assets/img/logo.png";
import SideNav from "./SideNav";
import Notification from "./Notification";
import StoreLinks from "./StoreLinks";
import BottomNav from "./BottomNav";
import Statistics from "./Statistics";
import PoxLegends from "./PoxLegends";
import GuidedTour from "./GuidedTour";

export default function MapLayout() {
  const { layer } = useSelector((state) => state.map);
  const dispatch = useDispatch();
  const has_been_guided = localStorage.getItem("UCPOX_has_been_guided");

  useEffect(() => {
    if (!Boolean(has_been_guided)) {
      dispatch(initGuidedTour());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-100 main-layout">
      <main>
        <header className="w-100">
          <Link to={"/"}>
            <img src={logo_png} alt="ucpox" className="logo" />
          </Link>

          <div className="header-actions">
            <Notification />

            <GuidedTourWrapper className="add-pox" stage={1}>
              <Link to={"/poxes/create"} className="btn btn-danger">
                <FaPlus />
                Add Pox
              </Link>
            </GuidedTourWrapper>
          </div>
        </header>
        <section className="flat-map">
          <MapView />

          <div style={{ position: "relative", zIndex: 1000 }}>
            <Outlet />
            {layer && <PoxLegends />}
            {layer && <Statistics />}
            <StoreLinks />
            <BottomNav />
          </div>
        </section>
      </main>

      <SideNav />
      <GuidedTour />
    </div>
  );
}
