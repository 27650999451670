import { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { useInfiniteQuery } from "react-query";
import { getUser } from "redux/features/auth.slice";
import {
  clearMapData,
  getMapData,
  openMyPoxes,
  restoreFromMyPoxes,
} from "redux/features/map.slice";
import { useAxiosPrivate, useWindowDimensions } from "hooks";
import { createClusterCustomIcon } from "./utils";
import { MapLoader } from "components";
import Markers from "./PoxMarker";
import LeafletGeoSearch from "./LeafletGeoSearch";
import PersistMapZoom from "./PersistMapZoom";
import Navigator from "./Navigator";
import ProjectInfo from "./ProjectInfo";
import Geofencing from "./Geofencing";
import instance from "apis/axios";
import "./styles.scss";

const LAST_POX_POPUP_OPENED = "LAST_POX_POPUP_OPENED";

export default function MapView() {
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { data, isLoading, param, payType, layer, filterQueries } = useSelector(
    (state) => state.map
  );
  const user = useSelector(getUser);
  const { width } = useWindowDimensions();

  const center = JSON.parse(localStorage.getItem(LAST_POX_POPUP_OPENED)) ?? [
    30, 40,
  ];

  useEffect(() => {
    switch (location.pathname) {
      // case "/":
      //   if (payType) {
      //     const queryString = `?payType=${payType}&type=${layer}${filterQueries}`;
      //     dispatch(getMapData({ query: queryString }));
      //   }
      //   break;

      case "/my-poxes":
        if (user) {
          dispatch(
            getMapData({
              instance: axiosPrivate,
              url: `/poxes?publishedBy=${user?.uid}`,
            })
          );
        }
        break;

      case "/saved-poxes":
        if (user) {
          dispatch(openMyPoxes());
          dispatch(
            getMapData({
              instance: axiosPrivate,
              url: `/saved-poxes`,
            })
          );
        }
        break;

      case "/geofence":
        if (user) {
          dispatch(openMyPoxes());
          dispatch(clearMapData());
        }
        break;

      default:
        break;
    }

    if (
      !payType &&
      !["/my-poxes", "/saved-poxes", "/geofence"].includes(location.pathname)
    ) {
      dispatch(restoreFromMyPoxes());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payType, layer, location.pathname, user]);

  const {
    data: map_data,
    isLoading: map_data_isloading,
    fetchNextPage: map_data_fetch_next_page,
  } = useInfiniteQuery(
    ["map-data", { param, payType, layer, filterQueries }],
    async ({ pageParam = 1 }) => {
      if (payType) {
        const queryString = `?status=active&payType=${payType}&type=${layer}&page=${pageParam}&per_page=${20}${filterQueries}`;
        const res = await instance.get(`/poxes/map${queryString}`);
        return res.data;
      }
      return {
        poxes: [],
        nBits: 0,
        meta: { next_page: null, has_next_page: false },
      };
    },
    {
      getNextPageParam(last_page) {
        return last_page.meta.next_page ?? undefined;
      },
      onSuccess(res_data) {
        if (res_data) {
          const next_page =
            res_data.pages[res_data.pages.length - 1].meta.next_page;
          if (next_page) {
            map_data_fetch_next_page({ pageParam: next_page });
          }
        }
      },
    }
  );

  return (
    <Fragment>
      <MapContainer
        style={{ height: "100%" }}
        center={center}
        zoom={width <= 480 ? 18 : location.pathname === "/" ? 4 : 2}
        minZoom={2}
        worldCopyJump={true}
        tap={false}
        className="map-view"
      >
        <TileLayer
          url="https://api.mapbox.com/styles/v1/decorsnap/ckqnfxsql0a7417o9413rjig4/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZGVjb3JzbmFwIiwiYSI6ImNrcTVjNmRwdzByM20ybnBwenVpbTBwcG8ifQ.-CUcCijKzxvvfry7qqxdzQ"
          attribution='© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        />

        {location.pathname === "/geofence" ? (
          user ? (
            <Geofencing />
          ) : null
        ) : (
          <MarkerClusterGroup
            iconCreateFunction={createClusterCustomIcon()}
            key={Date.now()}
            showCoverageOnHover
            spiderfyOnMaxZoom
          >
            {location.pathname === "/" ? (
              !map_data_isloading && (
                <Markers
                  data={map_data?.pages.reduce(
                    (a, b) => [...a, ...b.poxes],
                    []
                  )}
                  param={param}
                />
              )
            ) : isLoading ? (
              <div
                style={{
                  position: "absolute",
                  zIndex: 400,
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              >
                <MapLoader />
              </div>
            ) : (
              <Markers data={data} param={param} />
            )}
          </MarkerClusterGroup>
        )}

        {location.pathname === "/" && (
          <>
            <PersistMapZoom />
            <ProjectInfo />
          </>
        )}

        <LeafletGeoSearch />
        <Navigator {...{ LAST_POX_POPUP_OPENED }} />
        <ZoomControl position={"bottomleft"} />
      </MapContainer>
    </Fragment>
  );
}
