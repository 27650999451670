import * as yup from "yup";

export const groups = {
  main: [
    {
      id: "name",
      label: "Property Name",
      placeholder: "Enter Property Name",
      type: "text",
      required: true,
    },
    {
      id: "type",
      label: "Property Type",
      type: "select",
      options: ["Flat", "Plot", "Estab"],
      required: true,
    },
  ],
  contact: [
    {
      id: "contact_name",
      label: "Contact Name",
      placeholder: "Enter Contact Name",
      type: "text",
      required: true,
    },
    {
      id: "contact_number",
      label: "Contact Number",
      type: "input-select",
      input_type: "text",
      placeholder: "Enter Contact Number",
      options_id: "contact_number_cc",
      options: ["+91", "+971"],
      reverse: true,
      required: true,
    },
    {
      id: "ownership",
      label: "Ownership",
      type: "select",
      options: ["Self", "Others"],
      required: true,
    },
  ],
  Flat: [
    {
      id: "room_config",
      label: "Room Config",
      type: "select",
      options: ["1RK", "1BHK", "2BHK", "3BHK"],
      required: true,
    },
    {
      id: "bath",
      label: "Bath",
      type: "select",
      options: ["1", "2", "3", "4", "5"],
      required: true,
    },
    {
      id: "floor",
      label: "Floor",
      type: "select",
      options: ["Ground", "First", "Middle", "Top"],
      required: true,
    },
    {
      id: "facing",
      label: "Entance Face Direction",
      type: "select",
      options: [
        "North",
        "East",
        "West",
        "South",
        "North-East",
        "North-West",
        "South-East",
        "South-West",
      ],
    },
    {
      id: "area",
      label: "Built-Up Area",
      type: "input-select",
      input_type: "number",
      placeholder: "e.g. 1000",
      options_id: "area_unit",
      options: ["SQFTS", "SQMS", "CENTS", "ACRES"],
      required: true,
    },
    {
      id: "pay_type",
      label: "Payment Type",
      type: "select",
      options: ["Rent", "Sale"],
      required: true,
    },
  ],
  Plot: [
    {
      id: "plot_config",
      label: "Plot Type",
      type: "select",
      options: ["Residential", "Commercial", "Agricultural"],
      required: true,
    },
    {
      id: "area",
      label: "Plot Area",
      type: "input-select",
      input_type: "number",
      placeholder: "e.g. 1000",
      options_id: "area_unit",
      options: ["SQFTS", "SQMS", "CENTS", "ACRES"],
      required: true,
    },
    {
      id: "pay_type",
      label: "Payment Type",
      type: "select",
      options: ["Sale", "Lease"],
      required: true,
    },
  ],
  Estab: [
    {
      id: "area",
      label: "Floor Area",
      type: "input-select",
      input_type: "number",
      placeholder: "e.g. 1000",
      options_id: "area_unit",
      options: ["SQFTS", "SQMS", "CENTS", "ACRES"],
      required: true,
    },
    {
      id: "pay_type",
      label: "Payment Type",
      type: "select",
      options: ["Rent", "Sale"],
      required: true,
    },
  ],
  Rent: [
    {
      id: "rent_cost",
      label: "Rent",
      type: "input-select",
      input_type: "number",
      placeholder: "e.g. 1000",
      options_id: "currency",
      options: ["INR", "AED"],
      reverse: true,
      required: true,
    },
    {
      id: "deposit_cost",
      label: "Deposit",
      type: "input-select",
      input_type: "number",
      placeholder: "e.g. 1000",
      options_id: "currency",
      options: ["INR", "AED"],
      reverse: true,
      required: true,
    },
  ],
  Sale: [
    {
      id: "sale_cost",
      label: "Sale Cost",
      type: "input-select",
      input_type: "number",
      placeholder: "e.g. 1000",
      options_id: "currency",
      options: ["INR", "AED"],
      reverse: true,
      required: true,
    },
  ],
  Lease: [
    {
      id: "lease_cost",
      label: "Lease Cost",
      type: "input-select",
      input_type: "number",
      placeholder: "e.g. 1000",
      options_id: "currency",
      options: ["INR", "AED"],
      reverse: true,
      required: true,
    },
    {
      id: "lease_period",
      label: "Lease Duration (Days)",
      placeholder: "Enter Lease Duration",
      type: "number",
      required: true,
    },
  ],
};

export const initialValues = {
  images: [],
  name: "",
  type: undefined,
  room_config: undefined,
  bath: undefined,
  floor: undefined,
  facing: undefined,
  plot_config: undefined,
  area: undefined,
  area_unit: "SQFTS",
  pay_type: undefined,
  currency: "INR",
  rent_cost: undefined,
  deposit_cost: undefined,
  sale_cost: undefined,
  lease_cost: undefined,
  lease_period: undefined,
  turnover_cost: undefined,
  contact_name: "",
  contact_number: "",
  contact_number_cc: "+91",
  ownership: undefined,
  position: { lat: 20.5937, lng: 78.9629 },
  address: "",
  description: "",
};

export const validationSchema = yup.object({
  name: yup.string().required("Property Name is required"),
  address: yup.string().required("Address is required"),
  // description: yup.string().required("Description is required"),
  contact_name: yup.string().required("Contact Name is required"),

  contact_number: yup
    .number()
    .positive("Contact number must be a positive number")
    .required("Contact number is Required"),
  ownership: yup.string().required("Ownership is required"),
  // position: yup.object().required("Position is required"),
  type: yup.string().required("Property Type is required"),
  room_config: yup
    .string()
    .nullable()
    .test("if_rendered", "Room Config is required", function (value) {
      const { type } = this.parent;
      if (type === "Flat") {
        return value !== undefined;
      }
      return true;
    }),
  bath: yup
    .string()
    .nullable()
    .test("if_rendered", "Bath is required", function (value) {
      const { type } = this.parent;
      if (type === "Flat") {
        return value !== undefined;
      }
      return true;
    }),
  floor: yup
    .string()
    .nullable()
    .test("if_rendered", "Floor is required", function (value) {
      const { type } = this.parent;
      if (type === "Flat") {
        return value !== undefined;
      }
      return true;
    }),
  // facing: yup
  //   .string()
  //   .nullable()
  //   .test(
  //     "if_rendered",
  //     "Entrace Facing Direction is required",
  //     function (value) {
  //       const { type } = this.parent;
  //       if (type === "Flat") {
  //         return value !== undefined;
  //       }
  //       return true;
  //     }
  //   ),
  plot_config: yup
    .string()
    .nullable()
    .test("if_rendered", "Plot Config is required", function (value) {
      const { type } = this.parent;
      if (type === "Plot") {
        return value !== undefined;
      }
      return true;
    }),
  area: yup
    .number()
    .positive("Area must be a positive number")
    .required("Area is required"),
  pay_type: yup.string().required("Payment Type is required"),
  rent_cost: yup
    .number()
    .positive("Rent amount must be a positive number")
    .nullable()
    .test("if_rendered", "Rent Cost is required", function (value) {
      const { type, pay_type } = this.parent;
      if ((type === "Flat" || type === "Estab") && pay_type === "Rent") {
        return value !== undefined;
      }
      return true;
    }),
  deposit_cost: yup
    .number()
    .positive("Deposit amount must be a positive number")
    .nullable()
    .test("if_rendered", "Deposit Cost is required", function (value) {
      const { type, pay_type } = this.parent;
      if ((type === "Flat" || type === "Estab") && pay_type === "Rent") {
        return value !== undefined;
      }
      return true;
    }),
  sale_cost: yup
    .number()
    .positive("Sale amount must be a positive number")
    .nullable()
    .test("if_rendered", "Sale Cost is required", function (value) {
      const { pay_type } = this.parent;
      if (pay_type === "Sale") {
        return value !== undefined;
      }
      return true;
    }),
  lease_cost: yup
    .number()
    .positive("Lease amount must be a positive number")
    .nullable()
    .test("if_rendered", "Lease Cost is required", function (value) {
      const { type, pay_type } = this.parent;
      if (type === "Plot" && pay_type === "Lease") {
        return value !== undefined;
      }
      return true;
    }),
  lease_period: yup
    .number()
    .positive("Lease Duration must be a positive number")
    .nullable()
    .test("if_rendered", "Lease Duration is required", function (value) {
      const { type, pay_type } = this.parent;
      if (type === "Plot" && pay_type === "Lease") {
        return value !== undefined;
      }
      return true;
    }),
  turnover_cost: yup
    .number()
    .positive("Turnover amount must be a positive number")
    .nullable()
    .test("if_rendered", "Turnover is required", function (value) {
      const { type } = this.parent;
      if (type === "Estab") {
        return value !== undefined;
      }
      return true;
    }),
});
